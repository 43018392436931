<template>
    <div>
        <div class="course-banner kb">
            <img src="@/assets/images/EME_titlelogo_KB.png">
            <p>Kinder Beat<span class="reg">&reg;</span></p>
        </div>
        <div id="content" class="course-content">
            <div class="content-wide">
                <p class="course-title kb">Music Box Magic</p>
                <p class="course-subtitle">Early Childhood | 2 - 3 year olds</p>

                <p class="course-info-bold">
                    Kinder Beat is an outstanding introduction to music for children aged 2 - 5 years. This award-winning program features a progressive curriculum with activities that children need for cognitive development and accelerated learning.
                </p>
                <p class="course-info-bold">
                    Music Box Magic is the first level of Kinder Beat and is ideal for children aged 2 - 3 years.
                </p>

                <slot></slot>
            </div>
        </div>

        

        <div id="content" class="course-content-coloured column-container kb-grad">
            <div class="column">           
                <p class="course-section-heading">Focus Activities</p>

                <p class="course-info">
                    Focus activities develop the foundation skills necessary for all musicians.
                </p>

                <p class="course-info">
                    These include:
                </p>

                <ul>
                    <li class="course-info">Rhythm, beat, pulse and metre</li>
                    <li class="course-info">Body percussion</li>
                    <li class="course-info">Musical games and improvisation</li>
                    <li class="course-info">Singing songs, rhymes and solfa/solfege</li>
                    <li class="course-info">Movement, dance and gesture</li>
                    <li class="course-info">Percussion playing – tuned and untuned</li>
                    <li class="course-info">Listening, reflecting and responding</li>
                    <li class="course-info">Story-telling and mime</li>
                    <li class="course-info">Coordinating with a range of props</li>
                    <li class="course-info">Ensemble playing</li>
                    <li class="course-info">Reading, understanding and interacting with iconic and traditional notation</li>
                    <li class="course-info">Piano/Keyboard playing (Level 3)</li>
                </ul>
            </div>

            <div class="column">           
                <p class="course-section-heading">Musical Instruments and Props</p>

                <p class="course-info">
                    Kinder Beat lessons feature a variety of musical instruments and props to stimulate the senses and enrich the learning experience.
                </p>

                <p class="course-info">
                    These include:
                </p>

                <div class="column-container">
                    <div class="column">
                        <ul>
                            <li class="course-info">Kinder Bear</li>
                            <li class="course-info">The Music Box</li>
                            <li class="course-info">Rainbow Ring</li>
                            <li class="course-info">Parachute</li>
                            <li class="course-info">Streamers</li>
                            <li class="course-info">Hoops</li>
                            <li class="course-info">Scarves</li>
                            <li class="course-info">Balls</li>
                            <li class="course-info">Flash Cards</li>
                            <li class="course-info">Worksheets</li>
                        </ul>
                    </div>

                    <div class="column">
                        <ul>
                            <li class="course-info">Percussion Instruments</li>
                            <li class="course-info">Chime Bars/Tone Bars</li>
                            <li class="course-info">Glockenspiel</li>
                            <li class="course-info">Character Puppets</li>
                            <li class="course-info">Coloured Felt Strips</li>
                            <li class="course-info">Music Staff Mats (Level 3)</li>
                            <li class="course-info">Keyboard Mats (Level 3)</li>
                            <li class="course-info">Piano/Keyboard (Level 3)</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div id="content" class="course-content">
            <div class="course-content-left">
                <div style="padding:56.25% 0 0 0;position:relative;" class="video-container">
                    <iframe src="https://player.vimeo.com/video/574745497?title=0&byline=0&portrait=0" style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                </div>

                <p class="course-info-bold">
                    The vibrant grooves and contemporary flair of Kinder Beat are immediately apparent. Children instantly connect with the freshness and vitality of the songs.
                </p>

                <p class="course-info">
                    Our engaging characters – Kinder Bear, Kinder Roo, Joey and Super Cool Mouse – make every lesson a special experience for every child. They ensure that the fun factor is always embedded.
                </p>

                <p class="course-info">
                    Kinder Beat is developmentally precise.  It is a truly exciting and unique start to music that sets the global benchmark for early childhood music.
                </p>

                <p class="course-section-heading">Curriculum</p>

                <p class="course-info">
                    The progressive curriculum fosters effective learning through music play experiences.  Our building block approach ensures that knowledge, skills and musical understanding are extended with each new song.  Extension activities are a feature of every lesson.  These additional layers stretch the boundaries of music learning and promote individual creativity.  By level three, children are improvising on the piano, able to maintain their part in complex ensembles and have developed fundamental aural and music literacy skills.
                </p>

                <p class="course-info">
                    Whilst each Kinder Beat level flows into the next, they have also been designed as independent units.  This gives teachers the flexibility to choose the best starting level to suit the age and ability of each student.
                </p>

                <p class="course-info">
                    Children who successfully complete Kinder Beat Music Box Magic advance to <span class="hyper" @click="$emit('select', 'kbpp')">Kinder Beat Percussion Play</span>.
                </p>
            </div>

            <div class="course-content-right kb-grad">
                <div>
                    <div class="course-facts-row">
                        <p class="course-facts-title">Age</p>
                        <ul>
                            <li class="course-facts-content">Beginner students aged 2 - 3 years</li>
                        </ul>
                    </div>

                    <div class="course-facts-row">
                        <p class="course-facts-title">Duration</p>
                        <ul>
                            <li class="course-facts-content">6 - 9 months</li>
                        </ul>
                    </div>

                    <div class="course-facts-row">
                        <p class="course-facts-title">Student Materials</p>
                        <ul>
                            <li class="course-facts-content">Student Book</li>
                            <li class="course-facts-content">Audio Files</li>
                            <li class="course-facts-content">Kinder Bear</li>
                        </ul>
                    </div>

                    <div class="course-facts-row">
                        <p class="course-facts-title">Teacher Materials</p>
                        <ul>
                            <li class="course-facts-content">Teacher Guide</li>
                            <li class="course-facts-content">Teacher Edition Audio Files</li>
                            <li class="course-facts-content">Laminated Flash Cards</li>
                            <li class="course-facts-content">Certificates of Achievement</li>
                        </ul>
                    </div>

                    <div class="course-facts-row">
                        <p class="course-facts-title">Teaching Aids</p>
                        <ul>
                            <li class="course-facts-content">Assorted Props</li>
                            <li class="course-facts-content">Percussion Instruments</li>
                        </ul>
                    </div>
                </div>

                <div class="musicwiz-promo" @click="$emit('musicwiz')">
                    <img src="@/assets/images/musicwiz.png">
                    <p>Comes with the Encore Music Wiz App!</p>
                </div>
            </div>
        </div>
	</div>
</template>

<script>
export default {
    props: ['previewData']
}
</script>